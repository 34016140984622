<template>
    <div>
        <b-card>
            <h1>{{i18nT(`Two-factor authentication`)}}</h1>
            <!-- form -->
            <validation-observer ref="simpleRules">
            <b-form class="mt-2">
                <b-row>

                    <!-- Description -->
                    <b-col md="4">
                       <p class="" v-html="i18nT(`Two factor authentication is a feature that greatly enhances your account security.`)"></p>
                       <p class="" v-html="i18nT(`When enabled, it will require that you provide a security code of 6 digits after you submit your normal username and password. This code will be sent to you via SMS or WhatsApp message and you can also use e-mail as a backup delivery method.`)"></p>
                    </b-col>


                    <!-- Inputs -->
                    <b-col sm="8">
                        <b-row>
                            <b-col cols="12">
                                <b-form-group
                                    label-for="enable2FA"
                                    class="required"
                                >
                                    <b-form-checkbox
                                        name="enable2FA"
                                        switch
                                        v-model="optionsLocal.Enable2fa"
                                    >
                                        {{ i18nT(`Enable two-factor authentication for login`) }}
                                    </b-form-checkbox>

                                </b-form-group>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row  v-if="optionsLocal.Enable2fa">
                            <b-col cols="12">

                                <b-form-group
                                    :label="i18nT(`Select code delivery method`)"
                                    label-for="channel2fa"
                                >
                                    <b-form-radio-group
                                        name="channel2fa"
                                        v-model="optionsLocal.Channel2fa"
                                        :options="channels"
                                        aria-controls="ex-disabled-readonly"
                                    >
                                    </b-form-radio-group>
                                </b-form-group>

                            </b-col>

                            <b-col cols="12" class="pt-2">
                                <b-form-checkbox
                                    name="enable2FA"
                                    class="mb-2"
                                    switch
                                    v-model="optionsLocal.EmailEnabled2fa">
                                    {{ i18nT(`Enable code recovery over e-mail`) }}
                                </b-form-checkbox>
                            </b-col>

                            <b-col cols="9">
                                <b-form-group
                                    :label="i18nT(`Your current number (syncs with your profile)`)"
                                    label-for="phone"
                                    class="required"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Phone number"
                                        vid="phone"
                                        :rules="{ validPhoneNumber: mazphoneObj }"
                                    >
                                        <MazPhoneNumberInput
                                            v-model="optionsLocal.Phone"
                                            default-country-code="FR"
                                            @update="updatePhoneObj"
                                            :translations="{
                                              countrySelectorLabel: i18nT(`Country code`),
                                              countrySelectorError: i18nT(`Select a country`),
                                              phoneNumberLabel: i18nT(`Phone number`),
                                              example: i18nT(`Example:`),
                                            }"
                                        />
                                        <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col
                                v-if="false"
                                cols="4">
                                <b-button
                                    class="mt-2rem"
                                   variant="outline-primary"
                                   @click="validatePhoneNumber"
                                >
                                    {{i18nT(`Test`)}}
                                </b-button>
                            </b-col>


                        </b-row>

                    </b-col>
                    <!-- / Date format -->

                    <!-- buttons -->
                    <b-col cols="12">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mt-1 mr-1"
                            @click.prevent="validatePhoneNumber"
                        >
                            {{i18nT(`Save`)}}
                        </b-button>
                    </b-col>
                    <!--/ buttons -->
                </b-row>
            </b-form>
            </validation-observer>
        </b-card>



        <b-modal
            id="modal-verify"
            :title="i18nT(`Verify phone number`)"
            @ok="handleOk"
        >

            <b-row>
                <b-col cols="12">
                    <p>{{i18nT(`You will receive a verification code on the specified phone number. Please enter it below to validate your phone.`)}}</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                <b-form-group
                    :label="i18nT(`Verification code`)"
                    label-for="phone"
                    class="required"
                >
                    <b-form-input
                        v-model="ValidationCode"
                        type="text"
                        :placeholder="i18nT(`Enter validation code`)">
                    </b-form-input>
                </b-form-group>
            </b-col>
            </b-row>

        </b-modal>
    </div>

</template>

<script>
import {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    //BInputGroup,
    //BInputGroupAppend,
    //BFormSelect,
    BFormCheckbox,
    BFormRadioGroup,
    BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import useAuth from '@/auth/useAuth';


extend('validPhoneNumber', {
    params: ['phoneNumberData'],
    validate: (value, maz) => {
        return maz.phoneNumberData.isValid ;
    },
    message: `Invalid phone number`,
});



export default {
    components: {
        BFormRadioGroup,
        BFormCheckbox,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        BModal,
        //BInputGroup,
        //BInputGroupAppend,
        //BFormSelect,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    props: {
        generalData: {
            type: Object,
            default: () => {
                return {
                    FirstName: '',
                    LastName: '',
                }
            },
        },
    },
    data() {
        return {
            ValidationCode: '',
            optionsLocal: {},
            mazphoneObj: {},
            errors: [],
            channels: [
                { text: 'SMS', value: 'sms' },
                { text: 'WhatsApp', value: 'whatsapp' },
            ],
        }
    },
    created() {
       this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
        if(this.optionsLocal) {
            this.optionsLocal.Enable2fa = this.optionsLocal.Enable2fa === 1
            this.optionsLocal.EmailEnabled2fa = this.optionsLocal.EmailEnabled2fa === 1
        }
    },

    computed: {},
    methods: {
        handleOk() {

           this.$http.post('settings/checkVerificationCode', {
               Phone: this.optionsLocal.Phone,
               pin2fa: this.ValidationCode,
           }).then(()=>{

              this.ValidationCode = '';
              this.saveSettings();

           }).catch((err)=>{

               this.ValidationCode = '';
               this.$bvModal.show('modal-verify');

               this.$toast({
                   component: ToastificationContent,
                   position: 'top-right',
                   props: {
                       title: this.errorMessage(err),
                       icon: 'AlertTriangleIcon',
                       variant: 'danger',
                   },
               })
           })
        },

        validatePhoneNumber() {

            if(this.optionsLocal.Enable2fa){
                this.$http.post('settings/verifyPhone', {
                    Phone: this.optionsLocal.Phone,
                    Channel2fa: this.optionsLocal.Channel2fa,
                }).then(() => {
                    this.$bvModal.show('modal-verify');
                }).catch((err) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                });
            }else {
                this.saveSettings();
            }

        },

        saveSettings(){

            let payload = {
                Enable2fa: this.optionsLocal.Enable2fa ? 1 : 0,
                Channel2fa: this.optionsLocal.Channel2fa,
                EmailEnabled2fa: this.optionsLocal.EmailEnabled2fa ? 1 : 0,
                Phone: this.optionsLocal.Phone,
            }

            this.$http.post('settings/configuration2fa', payload).then(()=>{

                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(`Configuration Updated!`),
                        icon: 'InfoIcon',
                        variant: 'success',
                    },
                })
            }).catch((err)=>{
                console.log(err)
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(err),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
        },
        updatePhoneObj(dataCollected){
            this.mazphoneObj = dataCollected;
        },

        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const formData = new FormData()

                    formData.append('Language', this.optionsLocal.Language)
                    formData.append('DateFormat', this.optionsLocal.Timeformat)

                    this.$http.post(
                        'settings/profileConfiguration', formData
                    ).then(() => {
                        this.changeLang(this.optionsLocal.Language);
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.i18nT(`Configuration Updated!`),
                                icon: 'InfoIcon',
                                variant: 'success',
                            },
                        })

                        useAuth.setDateFormat(this.formatDict[this.optionsLocal.Timeformat])
                        useAuth.setDateFormatFlat(this.optionsLocal.Timeformat)
                        useAuth.setLocale(this.optionsLocal.Language)
                    })
                        .catch((err) => {
                            console.log(err)
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger',
                                },
                            })
                        })
                }
            })
        }
    },
}
</script>

<style>
.mt-2rem {
    margin-top: 2rem;
}
</style>
