<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{i18nT(`Profile`)}}</span>
      </template>

      <profile-general
        v-if="options"
        :general-data="options"
      />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="KeyIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{i18nT(`Change Password`)}}</span>
      </template>
      <profile-password :general-data="options" />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="FlagIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{i18nT(`Language & date`)}}</span>
      </template>
      <profile-configuration :config-data="options" />
    </b-tab>
      <b-tab>
          <template #title>
              <feather-icon
                  icon="ShieldIcon"
                  size="18"
                  class="mr-50"
              />
              <span class="font-weight-bold">{{i18nT(`Two-factor authentication`)}}</span>
          </template>
          <profile2-f-a v-if="options" :general-data="options" />
      </b-tab>

    <!--/ general tab -->
    <!--/ change password tab -->

    <!-- info -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import ProfileGeneral from './ProfileGeneral.vue'
import ProfilePassword from './ProfilePassword.vue'
import ProfileConfiguration from './ProfileConfiguration.vue'
import Profile2FA from "@/views/pages/profile/Profile2FA.vue";
// import ProfileSocial from './ProfileSocial.vue'
//import ProfileNotification from './ProfileNotification.vue'

export default {
  components: {
    BTabs,
    BTab,
    ProfileGeneral,
    ProfilePassword,
    ProfileConfiguration,
    Profile2FA,
    // ProfileSocial,
    //    ProfileNotification,
  },
  data() {
    return {
      options: null
    }
  },
  beforeCreate() {
    this.$http.get('users/profile').then(({ data }) => {
      this.options = data.data
    })
  }
}
</script>
