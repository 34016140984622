<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              :label="i18nT(`Old password`)"
              label-for="account-old-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Old password"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    :placeholder="i18nT(`Old password`)"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              :label="i18nT(`New password`)"
            >
              <validation-provider
                #default="{ errors }"
                name="New password"
                vid="Password"
                rules="required|password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    :placeholder="i18nT(`New password`)"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Password Confirm"
              rules="required|confirmed:Password"
            >
              <b-form-group
                label-for="account-retype-new-password"
                :label="i18nT(`Retype new password`)"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    :placeholder="i18nT(`New password`)"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ retype password -->

          <!-- PASSWORD RULES -->

          <div class="password-rules font-small-3 ml-2">
              <div class="pb-1">{{i18nT(`To ensure that your account and information is well protected, please chose a password that has: `)}}</div>
              <div class="d-flex">
                  <div class="rule-column pr-2">
                      <div :class="{ 'rule-met': isLengthValid }"><font-awesome-icon :icon="isLengthValid ? `check` : `times`" class="mr-1" :class="isLengthValid ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`Minimum 8 characters`)}}</div>
                      <div :class="{ 'rule-met': hasUppercase }"><font-awesome-icon :icon="hasUppercase ? `check` : `times`" class="mr-1" :class="hasUppercase ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`At least one uppercase letter`)}}</div>
                  </div>
                  <div class="rule-column">
                      <div :class="{ 'rule-met': hasNumber }"><font-awesome-icon :icon="hasNumber ? `check` : `times`" class="mr-1" :class="hasNumber ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`At least one number`)}}</div>
                      <div :class="{ 'rule-met': hasSpecialCharacter }"><font-awesome-icon :icon="hasSpecialCharacter ? `check` : `times`" class="mr-1" :class="hasSpecialCharacter ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`At least one special character`)}}</div>
                  </div>
              </div>
              <!-- Add other rules as needed -->
          </div>

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="onSave"
            >
              {{i18nT(`Save`)}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
              @click="onReset"
            >
              {{i18nT(`Reset`)}}
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      required
    }
  },
  computed: {
    isLengthValid() {
        return this.newPasswordValue.length >= 8;
    },
    hasUppercase() {
        return /[A-Z]/.test(this.newPasswordValue);
    },
    hasSpecialCharacter() {
        return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(this.newPasswordValue);
    },
    hasNumber() {
        return /\d/.test(this.newPasswordValue);
    },
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    onReset() {
      this.passwordValueOld = ''
      this.newPasswordValue = ''
      this.RetypePassword = ''
    },
    onSave() {
      if(this.newPasswordValue != this.RetypePassword) {        
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Passwords mismatch.`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.$refs.simpleRules.validate().then(success => {
        if(success) {
          const formData = new FormData()

          formData.append('Password', this.passwordValueOld)
          formData.append('NewPassword', this.newPasswordValue)

          this.$http.post(
            'settings/emailPassword', formData
          ).then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.successMessage(res),
                icon: 'InfoIcon',
                variant: 'success',
              },
            })
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.errorMessage(err),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    }
  },
}
</script>
