<template>
    <b-card>
        <h1>{{ i18nT(`Profile`) }}</h1>
        <validation-observer ref="simpleRules">
            <b-form class="mt-2">

                <h5 class="mt-2">{{ i18nT(`Personal information`) }}</h5>

                <b-row>
                    <b-col cols="12">
                        <avatar-control
                            :profileData="optionsLocal"
                            :UserImage="profileImage"
                            :parent-id="optionsLocal.Id"
                            @setNewAvatar="onSetNewAvatar"
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <!-- FIRST NAME -->
                    <b-col sm="6">
                        <b-form-group
                            :label="i18nT(`First name`)"
                            label-for="firstname"
                            class="required"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="First Name"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="optionsLocal.FirstName"
                                    placeholder="First Name"
                                    name="firstname"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <!-- LAST NAME -->
                    <b-col sm="6">
                        <b-form-group
                            :label="i18nT(`Last name`)"
                            label-for="lastName"
                            class="required"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Last Name"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="optionsLocal.LastName"
                                    name="lastName"
                                    placeholder="Last Name"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- EMAIL -->
                    <b-col sm="6">
                        <b-form-group
                            :label="i18nT(`Email`)"
                            label-for="email"
                            class="required"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Email"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="optionsLocal.Email"
                                    placeholder="Email"
                                    name="email"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <small class="text-muted">{{i18nT(`If you change your e-mail your login username will change to match it.`)}}</small>
                        </b-form-group>
                    </b-col>

                    <!-- PHONE -->
                    <b-col sm="6">
                        <b-form-group
                            :label="i18nT(`Phone number`)"
                            label-for="phone"
                            class="required"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Phone number"
                                vid="phone"
                                :rules="{ validPhoneNumber: mazphoneObj }"
                            >
                                <MazPhoneNumberInput
                                    v-model="optionsLocal.Phone"
                                    default-country-code="FR"
                                    @update="updatePhoneObj"
                                    :translations="{
                                      countrySelectorLabel: i18nT(`Country code`),
                                      countrySelectorError: i18nT(`Select a country`),
                                      phoneNumberLabel: i18nT(`Phone number`),
                                      example: i18nT(`Example:`),
                                    }"
                                />
                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>


                </b-row>

                <b-row class="pt-2">
                    <!-- COMPANY -->
                    <b-col sm="6">
                        <b-form-group
                            :label="i18nT(`Company`)"
                            label-for="company"
                        >
                            <b-form-input
                                v-model="optionsLocal.Company"
                                name="company"
                                placeholder="Company"
                            />
                        </b-form-group>
                    </b-col>

                    <!-- POSITION -->
                    <b-col sm="6">
                        <b-form-group
                            :label="i18nT(`Position`)"
                            label-for="position"
                        >
                            <b-form-input
                                v-model="optionsLocal.Position"
                                name="position"
                                placeholder="Position"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" class="pt-2">
                        <b-form-group
                            :label="i18nT(`Address`)"
                            label-for="address"
                        >
                            <b-form-input
                                v-model="optionsLocal.Address"
                                placeholder="Address"
                                name="address"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group
                            :label="i18nT(`City`)"
                            label-for="city"

                        >
                            <b-form-input
                                v-model="optionsLocal.City"
                                name="city"
                                placeholder="City"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group
                            :label="i18nT(`Zip`)"
                            label-for="zip"
                        >
                            <b-form-input
                                v-model="optionsLocal.Zip"
                                name="zip"
                                placeholder="Zip"
                            />

                        </b-form-group>
                    </b-col>
                    <b-col sm="12">
                        <b-form-group
                            :label="i18nT(`Country`)"
                            label-for="country"
                        >
                            <b-form-select
                                id="country"
                                v-model="optionsLocal.Country"
                                :options="countries"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mt-2 mr-1"
                            @click.prevent="validationForm"
                        >
                            {{ i18nT(`Save`) }}
                        </b-button>
                        <b-button
                            v-if="false"
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-secondary"
                            type="reset"
                            class="mt-2"
                            @click.prevent="resetForm"
                        >
                            {{ i18nT(`Reset`) }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    //BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    //BAlert,
    BCard,
    //BCardText,
    // BMedia,
    // BMediaAside,
    // BMediaBody,
    // BLink,
    // BImg,
    BFormSelect
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils';
import {ref} from '@vue/composition-api';
import {dictToSelectArray} from '@core/utils/utils';
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import {required} from '@validations';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import AvatarControl from "@/views/components/avatar/AvatarControl";

extend('validPhoneNumber', {
    params: ['phoneNumberData'],
    validate: (value, maz) => {
        return maz.phoneNumberData.isValid ;
    },
    message: `Invalid phone number`,
});

export default {
    components: {
        BButton,
        BForm,
        // BImg,
        // BFormFile,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        // BAlert,
        BCard,
        AvatarControl,
        // BCardText,
        // BMedia,
        // BMediaAside,
        // BMediaBody,
        // BLink,
        BFormSelect,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    props: {
        generalData: {
            type: Object,
            default: () => {
                return {
                    FirstName: '',
                    LastName: '',
                }
            },
        },
    },
    data() {
        return {
            optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
            profileFile: null,
            countries: [],
            UserImage: null,
            UserImageUrl: null,
            mazphoneObj: {},
        }
    },
    computed: {
        profileImage() {
            return null;
        }
    },
    methods: {
        onSetNewAvatar(newData) {
            this.UserImage = newData.newUserImage;
            this.UserImageUrl = newData.newImageUrl;
            this.optionsLocal = newData.newProfileData;
        },
        updatePhoneObj(dataCollected){
            this.mazphoneObj = dataCollected;
        },
        resetForm() {
            this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const formData = new FormData()

                    formData.append('Email', this.optionsLocal.Email)
                    formData.append('Address', this.optionsLocal.Address)
                    formData.append('Phone', this.optionsLocal.Phone)
                    formData.append('FirstName', this.optionsLocal.FirstName)
                    formData.append('LastName', this.optionsLocal.LastName)
                    formData.append('Company', this.optionsLocal.Company)
                    formData.append('Position', this.optionsLocal.Position)
                    formData.append('City', this.optionsLocal.City)
                    formData.append('Zip', this.optionsLocal.Zip)
                    formData.append('Country', this.optionsLocal.Country)
                    if (this.UserImage) formData.append('user_image', this.UserImage)

                    this.$http.post(
                        'users/profile', formData
                    ).then(() => {

                        this.$http.get('users/profile').then(({data}) => {
                            localStorage.setItem('userData', JSON.stringify(data.data))
                            this.$store.commit('app/UPDATE_LOCAL_USER', data.data)
                        })
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.i18nT(`Profile Updated`),
                                icon: 'InfoIcon',
                                variant: 'success',
                            },
                        })
                    })
                        .catch((err) => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger',
                                },
                            })
                        })
                }else{
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.i18nT(`Please fill all required fields`),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                }
            })
        }
    },
    setup() {
        const refInputEl = ref(null)
        const previewEl = ref(null)

        const {inputImageRenderer} = useInputImageRenderer(refInputEl, previewEl)

        return {
            refInputEl,
            previewEl,
            required,
            inputImageRenderer,
        }
    },
    created() {
        this.$http.get(
            `system/countries`
        ).then(({data}) => {
            this.countries = dictToSelectArray(data.data)
        })
    }
}
</script>
