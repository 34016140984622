<template>
    <b-card>
        <h1>{{i18nT(`Language & date`)}}</h1>
        <!-- form -->
        <validation-observer ref="simpleRules">
        <b-form class="mt-2">
            <b-row>
                <!-- Language -->
                <b-col md="6">
                    <b-form-group
                        :label="i18nT(`Language`)"
                        label-for="language"
                        class="required"
                    >

                        <validation-provider
                            v-slot="{ errors }"
                            name="Language`)"
                            rules="required"
                        >
                            <b-form-select id="language" v-model="optionsLocal.Language"
                                           :options="countries"
                                           :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <!-- / Language -->

                <!-- Date Format -->
                <b-col md="6">
                    <b-form-group
                        :label="i18nT(`Date format`)"
                        label-for="dateformat"
                        class="required"
                    >

                        <validation-provider
                            v-slot="{ errors }"
                            name="DateFormat`)"
                            rules="required"
                        >
                            <b-form-select id="dateformat" v-model="optionsLocal.Timeformat"
                                           :options="dateFormats"
                                           :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <!-- / Date format -->

                <!-- buttons -->
                <b-col cols="12">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mt-1 mr-1"
                        @click.prevent="validationForm"
                    >
                        {{i18nT(`Save`)}}
                    </b-button>
                    <b-button
                        v-if="false"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        variant="outline-secondary"
                        class="mt-1"
                    >
                        {{i18nT(`Reset`)}}
                    </b-button>
                </b-col>
                <!--/ buttons -->
            </b-row>
        </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BButton,
    BForm,
    BFormGroup,
    //BFormInput,
    BRow,
    BCol,
    BCard,
    //BInputGroup,
    //BInputGroupAppend,
    BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {dictToSelectArray} from "@core/utils/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import useAuth from '@/auth/useAuth';



export default {
    components: {
        BButton,
        BForm,
        BFormGroup,
        //BFormInput,
        BRow,
        BCol,
        BCard,
        //BInputGroup,
        //BInputGroupAppend,
        BFormSelect,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    props: {
        configData: {
            type: Object,
            default: () => {},
        },
    },
    mounted() {
        let self = this;
        this.$http.get(
            `system/cultures`
        ).then(({data}) => {
            self.countries = dictToSelectArray(data.data);
        });
        this.$http.get(
            `settings/timeformats`
        ).then(({data}) => {
            self.dateFormats = dictToSelectArray(data.data);
            this.formatDict = data.data
            //debugger;
        })
    },
    beforeCreate() {
        this.$http.get('users/profile').then(({data}) => {
            this.optionsLocal = data.data;
            //debugger;
        })
    },
    data() {
        return {
            passwordValueOld: '',
            newPasswordValue: '',
            RetypePassword: '',
            passwordFieldTypeOld: 'password',
            passwordFieldTypeNew: 'password',
            passwordFieldTypeRetype: 'password',

            optionsLocal: {
                Language: 'fr',
                Timeformat: 1,
            },
            countries: [],
            dateFormats: [
                {text: "MM/DD/YYYY", value: 1},
                {text: "DD/MM/YYYY", value: 2},
                {text: "YYYY/MM/DD", value: 3}
            ],
            errors: [],
            formatDict: {}
        }
    },
    computed: {
        passwordToggleIconOld() {
            return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        passwordToggleIconNew() {
            return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        passwordToggleIconRetype() {
            return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
    methods: {
        togglePasswordOld() {
            this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
        },
        togglePasswordNew() {
            this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
        },
        togglePasswordRetype() {
            this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const formData = new FormData()

                    formData.append('Language', this.optionsLocal.Language)
                    formData.append('DateFormat', this.optionsLocal.Timeformat)

                    this.$http.post(
                        'settings/profileConfiguration', formData
                    ).then(() => {
                        this.changeLang(this.optionsLocal.Language);
                        useAuth.setDateFormat(this.formatDict[this.optionsLocal.Timeformat])
                        useAuth.setDateFormatFlat(this.optionsLocal.Timeformat)
                        useAuth.setLocale(this.optionsLocal.Language)
                        
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.i18nT(`Configuration Updated!`),
                                icon: 'InfoIcon',
                                variant: 'success',
                            },
                        })

                    })
                        .catch((err) => {
                            console.log(err)
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger',
                                },
                            })
                        })
                }
            })
        }
    },
}
</script>
