<template>
    <b-row>
        <b-col cols="12">
            <div class="d-flex" :class="noBottomMargin ? '' : 'mb-2'">
                <b-avatar
                    :src="localImageUrl ? localImageUrl: profileData.ImageUrl"
                    :text="(profileData.FirstName ? profileData.FirstName.charAt(0) : '') + (profileData.LastName ? profileData.LastName.charAt(0) : '')"
                    class="mr-1 badge-light-primary"
                    size="6rem"
                >
                </b-avatar>
                <div class="">
                    <h2>{{ profileData.FirstName + ' ' + profileData.LastName }}</h2>
                    <b-form-file
                        v-model="imageFileObject"
                        class="hidden"
                        plain
                        ref="filePicker"
                        accept="image/*"
                        @change="onFileChange"
                    />
                    <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        class="my-1 mr-1"
                        @click="onFilePick"

                    >
                        <feather-icon icon="PlusCircleIcon" />
                        {{i18nT(`Set new photo`)}}
                    </b-button>
                    <span v-if="file">
                      {{fileName}}
                      <feather-icon icon="Trash2Icon" size="16" style="margin-right: 8px;" @click="onFileDelete"/>
                    </span>

                    <b-button
                        v-if="profileData.ImageUrl || localImageUrl"
                        variant="outline-danger"
                        class="mt-0"
                        @click="onDeleteAvatar()"
                    >
                        <feather-icon icon="MinusCircleIcon" />
                        {{i18nT(`Remove`)}}
                    </b-button>
                </div>
            </div>
        </b-col>
    </b-row>
</template>

<script>
import {
    BRow,
    BCol,
    BButton,
    BFormFile,
    BAvatar,
    VBModal,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive'
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    name: "AvatarControl",
    components: {
        BRow,
        BCol,
        BButton,
        BFormFile,
        BAvatar
    },
    directives: {
        Ripple,
        'b-modal': VBModal
    },
    props: {
        profileData: {
            type: Object,
            default: () => {
                return {
                    FirstName: '',
                    LastName: '',
                };
            }
        },
        UserImage: {
            default: null
        },
        parentId: {
            type: String | Number,
            default: null
        },
        noBottomMargin: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            file: null,
            UserImageUrl: null,
            computedImage: null,
            localImageUrl: null,
            imageFileObject: null,
        }
    },
    methods: {
        onFilePick: function() {
            this.$refs['filePicker'].$refs.input.click()
        },
        onFileChange(event){
            this.$emit('setNewAvatar', {
                newUserImage: event.target.files[0],
                newImageUrl: this.UserImageUrl,
                newProfileData: this.profileData
            });
        },
        onDeleteAvatar() {
            this.$swal({
                title: this.i18nT(`Are you sure you want to delete this image?`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Confirm`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value)

                    if(this.profileData.ImageUrl && this.profileData.Id) {
                    this.$http
                        .post(`main/clearAvatar?id=`+this.profileData.Id)
                        .then((data) => {
                            this.profileData.ImageUrl = null
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(data),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })

                            this.$http.get('users/profile').then(({data}) => {
                                localStorage.setItem('userData', JSON.stringify(data.data) )
                                this.$store.commit('app/UPDATE_LOCAL_USER', data.data)
                            })

                            // this.$router.push({ name: 'employees' })
                        })
                        .catch(ex => {
                            console.log('catch')
                            console.log(ex)
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Error occured`,
                                    iconF: 'InfoIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                } else {
                        this.localImageUrl = null;
                        this.imageFileObject = null;
                    }
            })
        }
    },
    watch: {
        file: function(val) {
            if (val) {
                this.file = val
                this.fileName = val.name
            } else {
                this.file = null
                this.fileName = ''
            }
        },
        imageFileObject: function(val) {
            if(val) {
                this.localImageUrl = URL.createObjectURL(val)
            }
        }

    }

}
</script>

<style scoped>

</style>
